import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumberFormatterPipe } from '@coin/admin/common/util';
import { DynamicDomAccessDirective, EmployeeIdPipe } from '@coin/modules/auth/data-management';
import { AuthComponentsModule } from '@coin/modules/auth/feature';
import { LoadingModule } from '@coin/shared/data-access';
import { FxConversionPipe, FxRatePipe } from '@coin/shared/data-management-pipes';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import {
  V2ButtonComponent,
  V2CardComponent,
  V2CheckboxComponent,
  V2CountryFlagComponent,
  V2DateInputComponent,
  V2DialogModule,
  V2SearchBoxComponent,
  V2TabsModule,
  V2TextAreaComponent,
  V2TextInputComponent,
  V2ToggleComponent
} from '@coin/shared/ui-storybook';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { TemplateWithValueDirective, TwoDecimalPlacesDirective } from '@coin/shared/util-directives';
import { MgmtGroupTranslationPipe, PureFunctionPipe, ThousandsSeparatorPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { SimplebarAngularModule } from 'simplebar-angular';
import { FooterComponent } from '../static/footer/footer.component';
import { AddPlanComponent } from './components/add-plan/add-plan.component';
import { AppCardComponent } from './components/app-card/app-card.component';
import { AppCardsComponent } from './components/app-cards/app-cards.component';
import { CalendarIconComponent } from './components/calendar-icon/calendar-icon.component';
import { EditSeasonPlanComponent } from './components/edit-season-plan/edit-season-plan.component';
import { EmployeeSearchBoxComponent } from './components/employee-search-box/employee-search-box.component';
import { EmployeeTargetOverviewComponent } from './components/employee-target-overview/employee-target-overview.component';
import { NavigationMenuWrapperComponent } from './components/navigation-menu-wrapper/navigation-menu-wrapper.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { PageHeadlineComponent } from './components/page-headline/page-headline.component';
import { RecordAchievementTableComponent } from './components/record-achievement-table/record-achievement-table.component';
import { SearchEmployeeDialogComponent } from './components/search-employee-dialog/search-employee-dialog.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { TableGroupDirective } from './components/simple-table/table-group.directive';
import { TabSelectionComponent } from './components/tab-selection/tab-selection.component';
import { TableDropdownEditFieldComponent } from './components/table-dropdown-edit-field/table-dropdown-edit-field.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { ViewInformationDialogComponent } from './components/view-information/parts/view-information-dialog/view-information-dialog.component';
import { ViewInformationComponent } from './components/view-information/view-information.component';
import { DocumentUploadDialogComponent } from './dialogs/document-upload/document-upload-dialog.component';
import { DocumentUploadDialogDirective } from './dialogs/document-upload/document-upload-dialog.directive';
import { LoginComponent } from './dialogs/login/login.component';
import { SelectPlanDialogComponent } from './dialogs/select-plan-dialog/select-plan-dialog.component';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { ImporterBaseService } from './services/importer-base.service';

@NgModule({
  declarations: [
    TabSelectionComponent,
    FooterComponent,
    LoginComponent,
    TagsInputComponent,
    SimpleTableComponent,
    EmployeeSearchBoxComponent,
    CalendarIconComponent,
    CountryCodePipe,
    EmployeeTargetOverviewComponent,
    TableGroupDirective,
    TableDropdownEditFieldComponent,
    RecordAchievementTableComponent,
    SelectPlanDialogComponent,
    AddPlanComponent,
    EditSeasonPlanComponent,
    DocumentUploadDialogComponent,
    DocumentUploadDialogDirective,
    ViewInformationComponent,
    ViewInformationDialogComponent,
    PageHeadlineComponent,
    NavigationMenuComponent,
    NavigationMenuWrapperComponent,
    AppCardComponent,
    AppCardsComponent,
    SearchEmployeeDialogComponent
  ],
  imports: [
    V2ToggleComponent,
    V2DateInputComponent,
    V2CheckboxComponent,
    DragScrollComponent,
    DragScrollItemDirective,
    DragDropModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    MatTabsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSliderModule,
    MatDialogModule,
    MatCardModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    CommonModule,
    SimplebarAngularModule,
    MatChipsModule,
    MatTooltipModule,
    InfiniteScrollDirective,
    MatProgressBarModule,
    TranslateModule.forChild(CustomTranslationLoader.createConfig(AppEnvironment.Admin, TranslationDebugParser)),
    ToastrModule.forRoot(),
    LegacyComponentsModule,
    DynamicDomAccessDirective,
    TwoDecimalPlacesDirective,
    EmployeeIdPipe,
    ThousandsSeparatorPipe,
    MgmtGroupTranslationPipe,
    FxConversionPipe,
    FxRatePipe,
    PureFunctionPipe,
    LoadingModule,
    NumberFormatterPipe,
    V2DialogModule,
    V2TabsModule,
    V2SearchBoxComponent,
    V2ButtonComponent,
    V2TextAreaComponent,
    V2TextInputComponent,
    TemplateWithValueDirective,
    AuthComponentsModule,
    V2CountryFlagComponent,
    V2CardComponent
  ],
  exports: [
    V2ToggleComponent,
    DragScrollComponent,
    DragScrollItemDirective,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    TabSelectionComponent,
    MatTabsModule,
    MatIconModule,
    MatSlideToggleModule,
    LoginComponent,
    MatCardModule,
    FooterComponent,
    TagsInputComponent,
    CountryCodePipe,
    MatPaginatorModule,
    MatMenuModule,
    SimpleTableComponent,
    EmployeeSearchBoxComponent,
    CalendarIconComponent,
    NumberFormatterPipe,
    EmployeeTargetOverviewComponent,
    TableDropdownEditFieldComponent,
    RecordAchievementTableComponent,
    AddPlanComponent,
    EditSeasonPlanComponent,
    DocumentUploadDialogComponent,
    ViewInformationComponent,
    PageHeadlineComponent,
    NavigationMenuComponent,
    NavigationMenuWrapperComponent,
    AppCardComponent,
    AppCardsComponent
  ],
  providers: [DatePipe, ImporterBaseService]
})
export class SharedModule {}
